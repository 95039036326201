/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { message } from 'antd';

import { preset } from 'styles';
import Spinner from '@components/Spinner';
import { DASHBOARD_REFRESH } from 'constant';
import axios from 'axios';
import FilterBar from 'components/FilterBar';
import { getApi, fetchApiErrorMessage } from '../../modules/api';

import {
  DashboardPrintHeader,
  DashboardPrintFooter,
} from '../Dashboard/components/DashboardPrintComponents';
import DashboardCharts from './components/DashboardCharts';

const EmbedDashboard = () => {
  const { embedId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [isDashboardLoading, setIsDashboardLoading] = useState(true);
  const [isAutoRefresh, setIsAutoRefresh] = useState(true);
  const [currentPageId, setCurrentPageId] = useState();

  const [dashboard, setDashboard] = useState({
    slug: '',
    name: '',
    dashboardVisualisations: [],
    pages: [],
    id: null,
    project: { slug: '' },
  });

  // Set currentPageId from URL
  useEffect(() => {
    let pageId;
    const { page: pageIndexStr, pageId: pageIdFromUrl } = queryString.parse(
      location.search
    );

    if (dashboard.pages.length > 0) {
      // Check if has pageIndex from url
      if (pageIndexStr) {
        const pageIndexFromUrl = Number(pageIndexStr);
        // Check if pageIndex in range
        if (dashboard.pages[pageIndexFromUrl - 1]) {
          pageId = dashboard.pages[pageIndexFromUrl - 1].id;
        } else {
          history.push({
            search: queryString.stringify({ page: 1 }),
          });
        }
      }

      if (
        pageIdFromUrl &&
        dashboard.pages.find(({ id }) => id === Number(pageIdFromUrl))
      ) {
        pageId = Number(pageIdFromUrl);
      }
    }

    setCurrentPageId(pageId);
  }, [location.search, dashboard.pages, history]);

  const canDrilldownData = false;

  // Fetch and Init Dashboard
  useEffect(() => {
    // This is used for aborting state transition if the component is unmounted
    let didCancel = false;
    // The below query format was choosen to stay consistent with the query built when sharing a dashboard while authenticated
    const { 'timeFilter[relative]': timeFilter } = queryString.parse(
      location.search
    );
    const fetchDashboard = async () => {
      setIsDashboardLoading(true);
      try {
        const baseUrl = `${process.env.REACT_APP_INSIGHT_API_URL}/embed/${embedId}`;
        let req;
        const reqOptions = {
          headers: { Accept: 'application/json' },
        };
        if (timeFilter) {
          req = await axios.get(
            `${baseUrl}?timefilter=${timeFilter}`,
            reqOptions
          );
        } else {
          req = await axios.get(`${baseUrl}`, reqOptions);
        }
        if (!didCancel) {
          setDashboard(req.data);
        }
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }

      setIsDashboardLoading(false);
    };

    fetchDashboard();

    return () => {
      didCancel = true;
    };
  }, [embedId, location.search]);

  const isDashboardInitialised = !isDashboardLoading;

  const isPrintingDashboard = true;

  // useEffect(() => {
  //   const refreshInterval = setInterval(() => {
  //     if (isAutoRefresh)
  //       dispatch({
  //         type: 'REFRESH_DASHBOARD_CHARTS',
  //       });
  //   }, DASHBOARD_REFRESH);
  //   return () => {
  //     clearInterval(refreshInterval);
  //   };
  // });

  return (
    <div>
      {/* <DashboardPrintHeader /> */}

      <div
        id="dashboard-charts-container"
        css={{
          paddingBottom: isPrintingDashboard
            ? preset.spacing(3)
            : 32 + preset.spacing(3),
        }}
      >
        {isDashboardInitialised ? (
          <>
            <DashboardCharts
              isPrintingDashboard={isPrintingDashboard}
              canDrilldownData={canDrilldownData}
              dashboardCharts={dashboard.dashboardVisualisations}
              currentPageId={currentPageId}
              pages={dashboard.pages}
            />

            <DashboardPrintFooter />
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default EmbedDashboard;
