/** @jsxImportSource @emotion/react */
import { useRef, useEffect, useState, useMemo } from 'react';
import { Chart, Coord, Axis, Guide, Geom, Shape, Legend } from 'bizcharts';

import formatMetricLabel from '@modules/chart/formatMetricLabel';
import useChartRef from '@modules/hooks/useChartRef';
import isNumber from '@modules/lib/isNumber';
import deepGet from '@modules/deepGet';
import ComparisonCard from '../components/ComparisonCard';

const { Text, Arc } = Guide;

const Gauge = ({
  width,
  height,
  config,
  color,
  data,
  queryObj,
  schema,
  renderer,
  setChartDownloadInstance,
}) => {
  const { max, min, threshold, format, label } = config;

  const [isShapeRegistered, setIsShapeRegistered] = useState(false);
  const [, forceRender] = useState();

  // Init chart instance
  const chart = useRef();

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance, isShapeRegistered);

  // Draw pointer
  useEffect(() => {
    Shape.registerShape('point', 'pointer', {
      drawShape(cfg, group) {
        let point = cfg.points[0];
        // eslint-disable-next-line react/no-this-in-sfc
        point = this.parsePoint(point);
        // eslint-disable-next-line react/no-this-in-sfc
        const center = this.parsePoint({
          x: 0,
          y: 0,
        });
        group.addShape('line', {
          attrs: {
            x1: center.x,
            y1: center.y,
            x2: point.x,
            y2: point.y,
            stroke: cfg.color,
            lineWidth: 5 * (height / 400),
            lineCap: 'round',
          },
        });
        return group.addShape('circle', {
          attrs: {
            x: center.x,
            y: center.y,
            r: 12 * (height / 400),
            stroke: cfg.color,
            lineWidth: 4.5 * (height / 400),
            fill: '#fff',
          },
        });
      },
    });

    setIsShapeRegistered(true);
  }, [height]);

  // Force chart re-render when height changes, so new pointer shape can apply
  useEffect(() => {
    if (chart.current) {
      forceRender(new Date());
    }
  }, [height]);

  // Format config values
  const rangeMin = isNumber(min) ? min : -1;
  const rangeMax = isNumber(max) ? max : 1;
  const minLabel = deepGet(label, ['min']);
  const maxLabel = deepGet(label, ['max']);
  const labelOffset = deepGet(label, ['offset']) || 20;
  const labelFontSize = deepGet(label, ['fontSize']) || 14;

  // Reset metric key
  const metricValueKey = 'value';

  // Get gauge value
  const gaugeValue =
    (Array.isArray(data[0])
      ? Object.values(data[0][0])[0]
      : Object.values(data[0])[0]) || 0;

  // Comparison gauge config
  const hasCompareValue = !!(
    queryObj.compare && typeof queryObj.compare === 'object'
  );

  // Config guage color range
  const colorRange = useMemo(() => {
    const { negative, positive } = threshold || {};
    const negativeThreshold =
      isNumber(negative) && negative >= rangeMin ? negative : rangeMin;
    const positiveThreshold =
      isNumber(positive) && positive <= rangeMax ? positive : rangeMax;

    return isNumber(negative) || isNumber(positive)
      ? {
          negative: [rangeMin, negativeThreshold],
          neutral: [negativeThreshold, positiveThreshold],
          positive: [positiveThreshold, rangeMax],
        }
      : {
          negative: [rangeMin, rangeMin],
          neutral: [rangeMin, gaugeValue],
          positive: [rangeMax, rangeMax],
        };
  }, [rangeMin, rangeMax, threshold, gaugeValue]);

  // Config chart scale
  const scale = useMemo(() => {
    const tickInterval = (rangeMax - rangeMin) / 4;
    const ticks = [
      rangeMin,
      rangeMin + tickInterval,
      rangeMin + tickInterval * 2,
      rangeMin + tickInterval * 3,
      rangeMax,
    ];
    return {
      [metricValueKey]: {
        min: rangeMin,
        max: rangeMax,
        ticks,
        nice: false,
        formatter: (val) => {
          switch (val) {
            case rangeMin:
              return minLabel || formatMetricLabel(val, 'bigNumber');
            case rangeMax:
              return maxLabel || formatMetricLabel(val, 'bigNumber');
            default:
              return null;
          }
        },
      },
    };
  }, [rangeMin, rangeMax, minLabel, maxLabel]);

  return (
    isShapeRegistered && (
      <Chart
        height={height}
        data={[{ value: gaugeValue }]}
        scale={scale}
        padding={['auto', 'auto', '15%', 'auto']}
        renderer={renderer}
        forceFit
        onGetG2Instance={(g2Chart) => {
          chart.current = g2Chart;
        }}
      >
        <Coord
          type="polar"
          startAngle={(-9 / 8) * Math.PI}
          endAngle={(1 / 8) * Math.PI}
          radius={0.75}
        />
        <Axis
          name="value"
          zIndex={2}
          line={null}
          label={{
            offset: labelOffset,
            textStyle: {
              fontSize: labelFontSize,
              textAlign: 'center',
              textBaseline: 'middle',
            },
          }}
          tickLine={{
            length: -24,
          }}
        />
        <Axis name="1" visible={false} />
        <Guide>
          <Arc
            zIndex={0}
            start={[rangeMin, 0.965]}
            end={[rangeMax, 0.965]}
            style={{
              stroke: 'rgba(0, 0, 0, 0.09)',
              lineWidth: 25,
            }}
          />
          {Object.keys(colorRange).map((colorRangeKey) => (
            <Arc
              key={colorRangeKey}
              zIndex={1}
              start={[colorRange[colorRangeKey][0], 0.965]}
              end={[colorRange[colorRangeKey][1], 0.965]}
              style={{
                stroke: color[colorRangeKey],
                lineWidth: 25,
              }}
            />
          ))}
          <Text
            position={['50%', '88%']}
            content={formatMetricLabel(gaugeValue, format)}
            style={{
              textAlign: 'center',
              fontSize: 32 * (height / 400),
              fill: color.text,
            }}
          />
        </Guide>
        <Geom
          type="point"
          position="value*1"
          shape="pointer"
          color={color.pointer}
          active={false}
          style={{ stroke: '#fff', lineWidth: 1 }}
        />

        {hasCompareValue && (
          <div
            css={{
              position: 'absolute',
              zIndex: 1,
              bottom: '8%',
              left: 0,
              right: 0,
              minHeight: 56,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ComparisonCard
              config={config}
              data={data}
              schema={schema}
              css={{
                transform: `scale(${height / 400}, ${height / 400})`,
              }}
            />
          </div>
        )}
        <Legend />
      </Chart>
    )
  );
};

export default Gauge;
