/** @jsxImportSource @emotion/react */
import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import {
    Chart, Tooltip, Geom, Label, Coord,
    Legend,
} from 'bizcharts';
import { View } from '@antv/data-set';

import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import formatMetricLabel from '@modules/chart/formatMetricLabel';
import formatOverflowLabel from '@modules/chart/formatOverflowLabel';
import formatChartData from '@modules/chart/formatChartData';
import getColorByMetric from '@modules/chart/getColorByMetric';
import useChartRef from '@modules/hooks/useChartRef';
import getChartElement from '@modules/chart/getChartElement';
import deepGet from '@modules/deepGet';

const TreeMap = ({
    width,
    height,
    config,
    color,
    queryObj,
    data,
    schema,
    drillDownDisabled = false,
    renderer,
    setChartDownloadInstance,
    // redux dispatch for triggering drilldown
    dispatch,
}) => {
    const {
        x, y, z, label: labelConfig,
    } = config;

    // Init chart instance
    const chart = useRef();

    // Re-init chart when width changes and pass chartRef to download modal
    useChartRef(chart, width, setChartDownloadInstance);

    // Remove empty x
    const formattedData = useMemo(() => formatChartData(data, [x]), [data, x]);

    // Transform to ploygon data
    const transformedData = useMemo(() => {
        const initTransform = {
            [x]: 'root',
            children: formattedData,
        };

        const dv = new View();
        dv.source(initTransform, { type: 'hierarchy' }).transform({
            field: y,
            type: 'hierarchy.treemap',
            tile: 'treemapBinary',
            as: ['x', 'y'],
        });

        const nodes = dv.getAllNodes();
        const transformedNodes = nodes.filter(node => node.parent).map(node => ({
            ...node,
            [x]: node.data[x],
            [y]: node.data[y],
            ...z ? { [z]: node.data[z] } : {},
        }));

        return transformedNodes;
    }, [formattedData, x, y, z]);

    // Config chart scale
    const scale = useMemo(() => ({
        [x]: {
            formatter: val => formatDimensionLabel(val, {
                timeInterval: deepGet(schema, [x, 'functionValue']),
                labelConfig,
            }),
        },
        [y]: {
            nice: false,
            formatter: val => formatMetricLabel(val),
        },
        ...z ? { [z]: { formatter: val => formatMetricLabel(val) } } : {},
    }), [schema, x, y, z, labelConfig]);

    // Config ploygon color for different types of data
    const polygonColor = useMemo(() => {
        if (z) {
            return getColorByMetric({
                x, config, color, data: formattedData, colorBy: z,
            });
        }
        return [x, xValue => color[xValue]];
    }, [color, config, formattedData, x, z]);

    // Get polygon tooltip
    const polygonTooltip = useMemo(() => (z ? `${x}*${y}*${z}` : [
        `${x}*${y}`,
        (xValue, yValue) => ({
            name: formatDimensionLabel(xValue, {
                timeInterval: deepGet(schema, [x, 'functionValue']),
                labelConfig,
            }),
            value: formatMetricLabel(yValue),
        }),
    ]), [schema, x, y, z, labelConfig]);

    // Format label
    const formatPolygonLabel = (val, item) => {
        if (val === 'root') return null;

        // Only display label that can fit into the box
        const itemW = item.x[1] - item.x[0];
        const itemH = item.y[2] - item.y[1];

        return formatOverflowLabel(val, itemW, itemH);
    };

    // Collect click ploygon data for drilldown
    const onPolygonClick = ev => {
        if (!drillDownDisabled) {
            const targetData = ev.data ? ev.data._origin.data : {};

            dispatch({
                type: 'SET_DRILLDOWN',
                drilldown: {
                    x: ev.x,
                    y: ev.y,
                    chartElement: getChartElement(ev, chart.current),
                    targetData,
                    queryObj,
                },
            });
        }
    };

    return (
        <Chart
            height={height}
            data={transformedData}
            scale={scale}
            padding="auto"
            renderer={renderer}
            forceFit
            onPolygonClick={ev => onPolygonClick(ev)}
            onGetG2Instance={g2Chart => { chart.current = g2Chart; }}
        >
            <Coord scale={[1, -1]} />
            <Tooltip showTitle={false} />
            <Geom
                type="polygon"
                position="x*y"
                color={polygonColor}
                tooltip={polygonTooltip}
                style={{
                    cursor: drillDownDisabled ? 'auto' : 'pointer',
                    lineWidth: 1,
                    stroke: '#fff',
                }}
            >
                <Label
                    content={x}
                    offset={0}
                    textStyle={{ fill: '#fff' }}
                    formatter={(val, item) => formatPolygonLabel(val, item)}
                />
            </Geom>
            <Legend />
        </Chart>
    );
};

export default connect()(TreeMap);
