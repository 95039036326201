/** @jsxImportSource @emotion/react */
import { useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Chart, Coord, Axis, Tooltip, Geom, Label, Legend } from 'bizcharts';
import { View } from '@antv/data-set';

import formatDimensionLabel from '@modules/chart/formatDimensionLabel';
import getColorByMetric from '@modules/chart/getColorByMetric';
import formatChartData from '@modules/chart/formatChartData';
import useChartRef from '@modules/hooks/useChartRef';
import getChartElement from '@modules/chart/getChartElement';
import deepGet from '@modules/deepGet';

import LegendConfig from '../components/LegendConfig';

const PieChart = ({
  dount = false,
  width,
  height,
  config,
  color,
  queryObj,
  data,
  schema,
  drillDownDisabled = false,
  renderer,
  setChartDownloadInstance,
  // redux dispatch for triggering drilldown
  dispatch,
}) => {
  const { x, y, colorBy, label: labelConfig } = config;
  const { dimensions } = queryObj;

  // Init chart instance
  const chart = useRef();

  // Re-init chart when width changes and pass chartRef to download modal
  useChartRef(chart, width, setChartDownloadInstance);

  // Get chart config
  const labelDisplay = config.label ? config.label.display : true;
  const noDimension = !(Array.isArray(dimensions) && dimensions.length > 0);

  // Config X and Y for two types of data
  const transformedX = noDimension ? 'label' : x;
  const transformedY = noDimension ? 'value' : y;

  // Remove empty x
  const formattedData = useMemo(() => {
    if (noDimension) {
      return Object.keys(data[0]).map((key) => ({
        label: key,
        value: data[0][key],
      }));
    }

    return formatChartData(data, [x]);
  }, [data, x, noDimension]);

  // Add percentage using bizcharts transform
  const transformedData = useMemo(() => {
    const dv = new View();
    dv.source(formattedData).transform({
      type: 'percent',
      field: transformedY,
      dimension: transformedX,
      as: 'percent',
    });

    return dv;
  }, [formattedData, transformedX, transformedY]);

  // Config chart scale
  const scale = useMemo(
    () => ({
      [transformedX]: {
        formatter: (val) =>
          formatDimensionLabel(val, {
            timeInterval: deepGet(schema, [transformedX, 'functionValue']),
            labelConfig,
          }),
      },
      percent: { formatter: (val) => `${(val * 100).toFixed(2)}%` },
    }),
    [schema, transformedX, labelConfig]
  );

  // Config pie color based on colorBy
  const pieColor = useMemo(() => {
    switch (colorBy) {
      case transformedY:
        return getColorByMetric({
          x: transformedX,
          config,
          color,
          data: transformedData,
          colorBy,
        });
      default:
        return [transformedX, (xValue) => color[xValue]];
    }
  }, [colorBy, color, transformedY, transformedX, config, transformedData]);

  // Collect click area data for drilldown
  const onPieClick = (ev) => {
    if (!drillDownDisabled && chart.current) {
      const targetData = ev.data ? ev.data.point || ev.data._origin : {};

      dispatch({
        type: 'SET_DRILLDOWN',
        drilldown: {
          x: ev.x,
          y: ev.y,
          chartElement: getChartElement(ev, chart.current),
          targetData,
          queryObj,
        },
      });
    }
  };

  return (
    <Chart
      height={height}
      data={transformedData}
      scale={scale}
      padding="auto"
      renderer={renderer}
      forceFit
      onIntervalClick={(ev) => onPieClick(ev)}
      onGetG2Instance={(g2Chart) => {
        chart.current = g2Chart;
      }}
    >
      <Coord type="theta" radius={0.75} innerRadius={dount ? 0.6 : 0} />
      <Axis name="percent" />
      <LegendConfig config={config} />
      <Tooltip showTitle={false} />
      <Geom
        type="intervalStack"
        position="percent"
        color={pieColor}
        style={{
          cursor: drillDownDisabled ? 'auto' : 'pointer',
          lineWidth: 1,
          stroke: '#fff',
        }}
      >
        {labelDisplay && (
          <Label
            content="percent"
            formatter={(val, item) =>
              `${formatDimensionLabel(item.point[transformedX], {
                timeInterval: deepGet(schema, [transformedX, 'functionValue']),
                labelConfig,
              })}: ${val}`
            }
          />
        )}
      </Geom>
      <Legend />
    </Chart>
  );
};

export default connect()(PieChart);
