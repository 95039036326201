/** @jsxImportSource @emotion/react */
import { useMemo, useState, useEffect, useContext, useRef } from 'react';
import { BackTop, message, Spin, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { useAuth } from '@otso/auth-wrapper';
import { v4 as uuid4 } from 'uuid';

import { trackEvent } from 'analytics';
import { preset } from 'styles';
import { arrayToObject } from '../../modules/items';
import {
  queryStringToObject,
  convertToURLParams,
} from '../../modules/queryStringConverter';
import { ProjectContext } from '../../providers/ProjectProvider';
import { ExploreProvider } from '../../providers/ExploreProvider';
import { getSharedSchemaFields } from '../../modules/filterBarHelpers';
import getExploreQuery from '../../modules/getExploreQuery';
import { postApi, fetchApiErrorMessage } from '../../modules/api';

import Container from '../../components/Container';
import Spinner from '../../components/Spinner';
import Empty from '../../components/Empty';
import FilterBar from '../../components/FilterBar';
import DocumentListHeader from './components/DocumentListHeader';
import DocumentCollapse from './components/DocumentCollapse';
import DocumentFlagModal from './components/DocumentFlagModal';

const Explore = () => {
  const {
    project: { slug: projectSlug },
  } = useContext(ProjectContext);
  const location = useLocation();

  const urlSearchParams = queryStringToObject(location.search);

  const filterBarRef = useRef();
  const scrollYRef = useRef();

  const {
    datasetIds: datasetIdsFromUrl = [],
    filters: filtersFromUrl = [],
    timeFilter: timeFilterFromUrl,
    docId,
    source,
  } = urlSearchParams || {};

  const fromDrilldown = source === 'drilldown';

  // applied datasetIds and filters
  const [appliedFilters, setAppliedFilters] = useState({
    datasetIds: datasetIdsFromUrl,
    filters: filtersFromUrl,
    timeFilter: timeFilterFromUrl,
  });
  const { datasetIds, filters, timeFilter } = appliedFilters;
  // documents
  const [documents, setDocuments] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [enrichmentCorrections, setEnrichmentCorrections] = useState({});
  const [pageSize, setPageSize] = useState(250);
  const [order, setOrder] = useState('newest');
  const [newDocIds, setNewDocIds] = useState([]);
  const [hideDuplicatedDocuments, setHideDuplicatedDocuments] = useState(
    !fromDrilldown
  );
  const [showParentDocmentsOnly, setShowParentDocmentsOnly] = useState(
    !fromDrilldown
  );
  // offset
  const [offset, setOffset] = useState(0);
  // saerch cache
  const [hasSearched, setHasSearched] = useState(false);
  // document flag
  const [flaggedDocument, setFlaggedDocument] = useState();

  const { user } = useAuth();

  // Get Project Datasets from Provider
  const {
    project: { datasets: projectDatasets = [], projectDatasetsById },
  } = useContext(ProjectContext);

  // Fetch Enrichment Corrections
  useEffect(() => {
    const fetchEnrichmentCorrections = async () => {
      try {
        const { data } = await postApi(
          `projects/${projectSlug}/enrichment-correction`,
          { documents: newDocIds }
        );

        setEnrichmentCorrections(data ? arrayToObject(data, 'documentId') : {});
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      }
    };

    if (newDocIds && newDocIds.length > 0) fetchEnrichmentCorrections();
  }, [projectSlug, newDocIds]);

  // Check if documents need special filtering
  const specialFiltering = useMemo(() => {
    const sharedSchemaFields = getSharedSchemaFields(
      datasetIds.reduce(
        (prev, datasetId) =>
          projectDatasetsById[datasetId]
            ? [...prev, projectDatasetsById[datasetId]]
            : prev,
        []
      ),
      { getAllFields: true }
    );

    return {
      hasDuplicateDocuments: !!sharedSchemaFields.find(
        (field) => field.name === 'duplicate_verbatim_id'
      ),
      hasDocumentThread: !!sharedSchemaFields.find(
        (field) => field.name === 'parent_id'
      ),
    };
  }, [datasetIds, projectDatasetsById]);

  // Config filters for duplicated documents and parent documents
  const configuredFilters = useMemo(() => {
    let newFilters = filters;

    if (specialFiltering.hasDuplicateDocuments && hideDuplicatedDocuments) {
      const hasNoDuplicateFilters = filters.find(
        (filter) =>
          filter.attribute === 'duplicate_verbatim_id' &&
          filter.condition === 'is null'
      );
      if (!hasNoDuplicateFilters) {
        newFilters = [
          ...newFilters,
          { attribute: 'duplicate_verbatim_id', condition: 'is null' },
        ];
      }
    }

    if (specialFiltering.hasDocumentThread && showParentDocmentsOnly) {
      const hasParentOnlyFilters = filters.find(
        (filter) =>
          filter.attribute === 'parent_id' && filter.condition === 'is null'
      );
      if (!hasParentOnlyFilters) {
        newFilters = [
          ...newFilters,
          { attribute: 'parent_id', condition: 'is null' },
        ];
      }
    }

    return newFilters;
  }, [
    filters,
    hideDuplicatedDocuments,
    showParentDocmentsOnly,
    specialFiltering.hasDocumentThread,
    specialFiltering.hasDuplicateDocuments,
  ]);

  // Check if it is ready for searching
  const isExploreSearchReady = useMemo(
    () =>
      Array.isArray(datasetIds) &&
      datasetIds.length > 0 &&
      projectDatasets.length > 0,
    [datasetIds, projectDatasets.length]
  );

  // Trigger Explore search
  useEffect(() => {
    const query = getExploreQuery({
      order,
      datasetIds,
      offset,
      filters: configuredFilters,
      timeFilter,
      limit: pageSize,
    });

    const fetchDocuments = async () => {
      try {
        setIsDocumentsLoading(true);
        setHasSearched(true);

        const res = await postApi(`projects/${projectSlug}/query`, query);
        const { data, total } = res.data;
        setDocuments(data);

        if (typeof total === 'number') {
          setTotalDocuments(total);
        }

        setNewDocIds(data.map((doc) => doc.otso_doc_id));
      } catch (error) {
        message.error(fetchApiErrorMessage(error));
      } finally {
        setIsDocumentsLoading(false);
      }
    };

    if (isExploreSearchReady) fetchDocuments();
  }, [
    isExploreSearchReady,
    datasetIds,
    offset,
    configuredFilters,
    order,
    pageSize,
    projectSlug,
    timeFilter,
  ]);
  // Scroll Page to the expanded document if expandedDocumentId found
  useEffect(() => {
    if (docId && documents.length > 0) {
      const targetExploreCard = document.getElementById(
        `explore-card-${docId}`
      );
      if (targetExploreCard) {
        targetExploreCard.scrollIntoView();
      }
    }
  }, [documents.length, docId]);

  // Keep page sroll pos unchanged when load more
  useEffect(() => {
    if (documents.length > 0 && scrollYRef.current) {
      window.scrollTo(0, scrollYRef.current);
      scrollYRef.current = 0;
    }
  }, [documents.length]);

  // Handle load more
  const loadNextPage = (page) => {
    trackEvent('Load More Explore Documents');
    setOffset((page - 1) * pageSize);
  };

  const copyDocumentLink = (documentId) => {
    if (filterBarRef.current) {
      const exportedFilters = filterBarRef.current.getExportedFilters();
      const urlParamString = convertToURLParams({
        filters: exportedFilters.filters,
        ...(exportedFilters.timeFilter
          ? { timeFilter: exportedFilters.timeFilter }
          : {}),
        datasetIds,
        docId: documentId,
        org: user?.currentOrg,
      });

      copy(
        `${window.location.origin}${window.location.pathname}?${urlParamString}`
      );
    }
  };

  const documentsLength = documents.length;

  const helpMessage = (
    <Typography
      css={{ 'div.ant-typography': { marginBottom: preset.spacing(1) } }}
    >
      <Typography.Paragraph>
        The search bar will suggest filter options based on what you’re typing.
        If you click any of the suggestions below the search bar, we will
        automatically provide the correct syntax for these searches.
      </Typography.Paragraph>
      <Typography.Paragraph>
        You can also click the “Filters ▼” toggle to see the filter options
        applied by your search query.
      </Typography.Paragraph>
      <Typography.Paragraph>
        To get started, try typing something based on what you’d like to filter
        for, such as <Typography.Text code>Date:</Typography.Text> or{' '}
        <Typography.Text code>Sentiment:</Typography.Text>. Alternatively use
        “double quotes” for an exact phrase match, and ‘single quotes’ for a
        loose phrase match that allows for variations in wording.
      </Typography.Paragraph>
    </Typography>
  );

  return (
    <ExploreProvider>
      <Container paddingY={2}>
        <FilterBar
          useQueryInput
          ref={filterBarRef}
          initialDatasetIds={datasetIdsFromUrl}
          initialFilters={filtersFromUrl}
          initialTimeFilter={timeFilterFromUrl}
          isSearchLoading={isDocumentsLoading}
          onApply={(newFilters) => {
            setOffset(0);
            setAppliedFilters(newFilters);
          }}
          helpMessage={helpMessage}
        />

        {documentsLength > 0 && projectDatasets.length > 0 ? (
          <div>
            <DocumentListHeader
              // pagination
              pageSize={pageSize}
              setPageSize={(newPageSize) => {
                setOffset(0);
                setPageSize(newPageSize);
              }}
              // datasets
              datasetsById={projectDatasetsById}
              datasetIds={datasetIds}
              // documents
              documents={documents}
              totalDocuments={totalDocuments}
              isDocumentsLoading={isDocumentsLoading}
              // orderBy
              order={order}
              setOrder={(newOrder) => {
                setOffset(0);
                setOrder(newOrder);
              }}
              // Load next page
              loadNextPage={loadNextPage}
              // special document filter toggle
              specialFiltering={specialFiltering}
              hideDuplicatedDocuments={hideDuplicatedDocuments}
              setHideDuplicatedDocuments={setHideDuplicatedDocuments}
              showParentDocmentsOnly={showParentDocmentsOnly}
              setShowParentDocmentsOnly={setShowParentDocmentsOnly}
            />
            <Spin
              size="large"
              spinning={isDocumentsLoading}
              css={{ marginBottom: preset.spacing(2) }}
            >
              {documents.map((document) => (
                <DocumentCollapse
                  key={`${document.datasetId}_${document.id || uuid4()}`}
                  document={document}
                  dataset={projectDatasetsById[document.datasetId]}
                  hasEnrichmentCorrection={
                    !!enrichmentCorrections[document.otso_doc_id]
                  }
                  projectSlug={projectSlug}
                  expandedDocumentId={docId}
                  appliedFilters={appliedFilters}
                  copyDocumentLink={copyDocumentLink}
                  deleteDocument={() => setFlaggedDocument(document)}
                />
              ))}
            </Spin>
            <BackTop
              css={{ right: preset.spacing(5), bottom: preset.spacing(5) }}
            />
          </div>
        ) : isDocumentsLoading ? (
          <Spinner />
        ) : (
          <Empty
            description={
              hasSearched
                ? 'No Results Found'
                : 'Select dataset/s above to explore your data'
            }
          />
        )}
      </Container>

      <DocumentFlagModal
        flaggedDocument={flaggedDocument}
        onCancel={() => setFlaggedDocument()}
      />
    </ExploreProvider>
  );
};

export default Explore;
